import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'

import Logo from '../Logo'
import DesktopMenu from '../DesktopMenu'
import MobileMenu from '../MobileMenu'
import * as s from './Header.module.scss'

const Header = () => {
  const { datoCmsHeader } = useStaticQuery(graphql`
    query Header {
      datoCmsHeader {
        phonesHeading
        phones {
          id
          text
        }
        scheduleHeading
        schedules {
          id
          text
        }
        addressesHeading
        addresses {
          id
          text
        }
      }
    }
  `)

  const {
    phonesHeading,
    phones,
    scheduleHeading,
    schedules,
    addressesHeading,
    addresses,
  } = datoCmsHeader

  return (
    <div className={s.header}>
      <Container as="header" className={s.header_inner}>
        <Logo />
        <DesktopMenu className={s.menu} />
        <MobileMenu
          phonesHeading={phonesHeading}
          phones={phones}
          addressesHeading={addressesHeading}
          addresses={addresses}
          scheduleHeading={scheduleHeading}
          schedules={schedules}
          className={s.mobileMenu}
        />
        <span className={s.phone}>{phones[0].text}</span>
      </Container>
    </div>
  )
}

export default Header
