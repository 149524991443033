const MENU = [
  {
    id: 'hero',
    name: 'Головна',
    link: '#hero',
  },
  {
    id: 'services',
    name: 'Послуги',
    link: '#services',
  },
  {
    id: 'team',
    name: 'Команда',
    link: '#team',
  },
  {
    id: 'guarantees',
    name: 'Гарантії',
    link: '#guarantees',
  },
  {
    id: 'articles',
    name: 'Статті',
    link: '#articles',
  },
  {
    id: 'contacts',
    name: 'Контакти',
    link: '#contacts',
  },
]

export default MENU
