import React from 'react'

import * as s from './Logo.module.scss'

const Logo = () => (
  <div className={s.logo}>
    Фаворит<span>.</span>
  </div>
)

export default Logo
