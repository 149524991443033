import React from 'react'
import { Nav } from 'react-bootstrap'
import PropTypes from 'prop-types'

import useMenu from '~hooks/useMenu'
import MENU from '~constants/menu'

const Menu = (props) => {
  const activeLinkIndex = useMenu()
  const { variant, handleScroll, className } = props

  return (
    <Nav as="ul" variant={variant} className={className}>
      {MENU.map(({ name, link }, i) => (
        <Nav.Item as="li" key={name}>
          <Nav.Link
            title={name}
            href={`${link}`}
            onClick={(e) => handleScroll(e, link)}
            active={i === activeLinkIndex}
          >
            {name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
  className: '',
}

Menu.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
}

export default Menu
