import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'

import Logo from '../Logo'
import * as s from './Footer.module.scss'

const Footer = () => {
  const {
    datoCmsFooter: { copyright, rights },
  } = useStaticQuery(graphql`
    query Footer {
      datoCmsFooter {
        copyright
        rights
      }
    }
  `)

  return (
    <div className={s.footer}>
      <Container as="footer" className={s.footer_inner}>
        <Logo />
        <div className={s.rights}>
          <span>{copyright}</span> {rights}
        </div>
      </Container>
    </div>
  )
}

export default Footer
