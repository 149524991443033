import React from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import Menu from '~components/Layout/components/Menu'

const DesktopMenu = ({ className }) => {
  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e, link) => {
    e.preventDefault()
    gsap.to(window, {
      scrollTo: { y: link, offsetY: 78 },
      ease: 'power2',
    })
  }

  return <Menu handleScroll={handleScroll} className={className} />
}

DesktopMenu.defaultProps = {
  className: '',
}

DesktopMenu.propTypes = {
  className: PropTypes.string,
}

export default DesktopMenu
