import { useEffect, useRef, useState } from 'react'
import MENU from '~constants/menu'

const useMenu = () => {
  const [activeLinkIndex, setActiveLinkIndex] = useState(0)
  const sections = useRef([])

  useEffect(() => {
    sections.current = MENU.map(({ id }) => document.getElementById(id))

    const handleActiveLink = () => {
      let activeIndex = 0
      sections.current.forEach((section, i) => {
        if (section?.offsetTop <= window.scrollY + 80) activeIndex = i
      })
      setActiveLinkIndex(activeIndex)
    }

    handleActiveLink()
    window.addEventListener('scroll', handleActiveLink)

    return () => {
      window.removeEventListener('scroll', handleActiveLink)
    }
  }, [])

  return activeLinkIndex
}

export default useMenu
