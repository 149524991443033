import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import Icon from '~components/Icon'

import Menu from '../Menu'
import BtnAnimatedBurger from './components/BtnAnimatedBurger'
import * as s from './MobileMenu.module.scss'

const MobileMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  gsap.registerPlugin(ScrollToPlugin)

  const {
    phonesHeading,
    phones,
    addressesHeading,
    addresses,
    scheduleHeading,
    schedules,
    className,
    ...rest
  } = props

  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e, link) => {
    e.preventDefault()
    setIsOpen(false)
    gsap.to(window, {
      scrollTo: { y: link, offsetY: 78 },
      ease: 'power2',
    })
  }

  const infoData = [
    {
      iconName: 'icon-phone',
      title: phonesHeading,
      items: phones,
    },
    {
      iconName: 'icon-clock',
      title: scheduleHeading,
      items: schedules,
    },
    {
      iconName: 'icon-location',
      title: addressesHeading,
      items: addresses,
    },
  ]

  return (
    <div {...rest} className={className}>
      <BtnAnimatedBurger isOpen={isOpen} setIsOpen={setIsOpen} />
      <Offcanvas show={isOpen} placement="top" backdrop={false}>
        <Offcanvas.Body>
          <Menu handleScroll={handleScroll} variant="mob" />

          <div className={s.wrapper}>
            {infoData.map(({ iconName, title, items }) => (
              <div key={title} className={s.infoWrapper}>
                <Icon name={iconName} size={28} />
                <div className={s.info}>
                  <h5 className={s.info_title}>{title}</h5>
                  {items.map(({ id, text }) => (
                    <p key={id}>{text}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

MobileMenu.defaultProps = {
  className: '',
}

MobileMenu.propTypes = {
  phonesHeading: PropTypes.string.isRequired,
  phones: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string })
  ).isRequired,
  addressesHeading: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string })
  ).isRequired,
  scheduleHeading: PropTypes.string.isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string })
  ).isRequired,
  className: PropTypes.string,
}

export default MobileMenu
